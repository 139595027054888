import React, { useEffect, useState } from 'react';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { Button, Table, Spinner, Toast, ToastContainer } from 'react-bootstrap';
import { CSVLink } from 'react-csv';

function Dashboard() {
    const navigate = useNavigate();
    const auth = getAuth();
    const [links, setLinks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                fetchLinks(user.email);
            } else {
                navigate('/login');
            }
        });

        return () => unsubscribe();
    }, [auth, navigate]);

    const fetchLinks = async (userEmail) => {
        setLoading(true);
        try {
            const linksQuery = query(
                collection(db, 'links'),
                where('email', '==', userEmail)
            );

            const querySnapshot = await getDocs(linksQuery);
            const userLinks = querySnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    ...data,
                    clickCount: calculateUniqueScans(data.interactions || []),
                    createdAt: data.createdAt ? data.createdAt.toDate().toLocaleString() : 'N/A',
                };
            });

            setLinks(userLinks);
        } catch (error) {
            console.error('Error fetching links:', error);
            setError('Failed to load your links. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const calculateUniqueScans = (interactions) => {
        const uniqueDevices = new Set();
        interactions.forEach(interaction => {
            uniqueDevices.add(interaction.device);
        });
        return uniqueDevices.size;
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const handleDelete = async (linkId) => {
        try {
            await deleteDoc(doc(db, 'links', linkId));
            setLinks(links.filter(link => link.id !== linkId));
        } catch (error) {
            console.error('Error deleting link:', error);
            setError('Failed to delete the link.');
        }
    };

    const handleCopyLink = (link) => {
        navigator.clipboard.writeText(link.newLink);
        alert('Link copied to clipboard!');
    };

    return (
        <div className="container mt-5">
            <h2>Dashboard</h2>
            <p>Welcome, {auth.currentUser?.email}</p>

            {loading ? (
                <Spinner animation="border" />
            ) : (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Uniq ID</th>
                            <th>Time</th>
                            <th>Business Name</th>
                            <th>Google Generated Link</th>
                            <th>Scanned No</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {links.map((link) => (
                            <tr key={link.id}>
                                <td>{link.uniqueId}</td>
                                <td>{link.createdAt}</td>
                                <td>{link.businessName || 'N/A'}</td>
                                <td>
                                    <a href={link.link} target="_blank" rel="noopener noreferrer">
                                        {link.link}
                                    </a>
                                </td>
                                <td>{link.clickCount}</td>
                                <td>
                                    <Button variant="primary" onClick={() => handleCopyLink(link)} size="sm">Copy</Button>{' '}
                                    <Button variant="danger" onClick={() => handleDelete(link.id)} size="sm">Delete</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}

            {error && (
                <ToastContainer position="top-end" className="p-3">
                    <Toast onClose={() => setError('')} show={error !== ''} delay={3000} autohide>
                        <Toast.Body>{error}</Toast.Body>
                    </Toast>
                </ToastContainer>
            )}

            <CSVLink data={links} filename="links.csv" className="btn btn-success mt-4">
                Export to CSV
            </CSVLink>

            <Button onClick={handleLogout} className="btn btn-secondary mt-4">
                Logout
            </Button>
        </div>
    );
}

export default Dashboard;
