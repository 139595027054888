// src/components/Success.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase'; // Adjust import path
import { getFirestore, doc, getDoc } from 'firebase/firestore';

function Success() {
  const navigate = useNavigate();
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      const user = auth.currentUser;
      if (user) {
        const db = getFirestore();
        const subscriptionRef = doc(db, 'subscriptions', user.uid);
        const subscriptionDoc = await getDoc(subscriptionRef);

        if (subscriptionDoc.exists()) {
          setSubscriptionDetails(subscriptionDoc.data());
        }
        setLoading(false);
      } else {
        navigate('/login'); // Redirect to login if no user is authenticated
      }
    };

    fetchSubscriptionDetails();
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="text-center mt-5">
      <h2>Payment Successful!</h2>
      <p>Thank you for your subscription.</p>

      {subscriptionDetails && (
        <div>
          <p><strong>Plan:</strong> {subscriptionDetails.planName || 'Basic Plan'}</p>
          <p><strong>Start Date:</strong> {subscriptionDetails.startDate ? subscriptionDetails.startDate.toDate().toLocaleDateString() : 'N/A'}</p>
          <p><strong>Status:</strong> {subscriptionDetails.subscriptionStatus}</p>
        </div>
      )}

      <button onClick={() => navigate('/dashboard')} className="btn btn-primary mt-4">
        Go to Dashboard
      </button>
      <button onClick={() => navigate('/')} className="btn btn-secondary mt-4">
        Go to Home
      </button>
    </div>
  );
}

export default Success;
