// src/components/Login.js
import React from 'react';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase'; // Adjust the path if needed

function Login() {
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();

  const handleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      console.log('User signed in:', result.user);

      // Fetch the ID token
      const idToken = await result.user.getIdToken();
      console.log('ID Token:', idToken);

      // Use the token as needed, for example, store it for making API requests
      // You could store it in localStorage or sessionStorage if necessary
      // sessionStorage.setItem('idToken', idToken);

      navigate('/dashboard'); // Redirect to dashboard after login
    } catch (error) {
      console.error('Error signing in:', error);
    }
  };

  return (
    <div className="text-center mt-5">
      <h2>Login</h2>
      <button onClick={handleLogin} className="btn btn-primary">
        Sign in with Google
      </button>
    </div>
  );
}

export default Login;
