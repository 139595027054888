import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { auth } from './firebase';  // Import the auth instance
import Header from './components/Header';
import LinkForm from './components/LinkForm';
import SearchAnalytics from './components/SearchAnalytics';
import Subscribe from './components/Subscribe';
import Success from './components/Success';
import Cancel from './components/Cancel';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import TrackLink from './components/TrackLink';  // Import the TrackLink component

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <Header user={user} />
      <Routes>
        <Route path="/" element={<LinkForm />} />
        <Route path="/track/:id" element={<TrackLink />} />
        <Route path="/search" element={<SearchAnalytics />} />
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/success" element={<Success />} />
        <Route path="/cancel" element={<Cancel />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </Router>
  );
}

export default App;
