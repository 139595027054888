import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase'; // Ensure this path is correct and points to your firebase configuration
import { loadStripe } from '@stripe/stripe-js';
import config from '../config/config'; // Import the configuration file

// Initialize Stripe.js with your publishable key from the config file
const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY);

function Subscribe() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Track loading state

  useEffect(() => {
    // Firebase auth listener to handle user state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setLoading(false); // User is authenticated, no redirect needed
      } else {
        navigate('/login'); // Redirect to login if the user is not authenticated
      }
    });

    return () => unsubscribe(); // Cleanup listener on unmount
  }, [navigate]);

  const handleSubscribe = async () => {
    const stripe = await stripePromise;

    try {
      const idToken = await auth.currentUser.getIdToken();

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        }
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        throw new Error('Failed to create checkout session');
      }

      const session = await response.json();

      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        console.error(result.error.message);
      }
    } catch (error) {
      console.error('Error during subscription:', error.message);
    }
  };

  if (loading) return <div>Loading...</div>; // Optional loading state UI

  return (
    <div className="text-center mt-5">
      <h2>Subscribe to a Plan</h2>
      <p>Choose a plan that fits your needs.</p>

      <button onClick={handleSubscribe} className="btn btn-primary mt-4">
        Subscribe Now
      </button>
    </div>
  );
}

export default Subscribe;
