// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore, serverTimestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCUJxL3Spkr_RQHnRogR7a8hnodzhOVsWs",
  authDomain: "nfcanalytics-13f07.firebaseapp.com",
  projectId: "nfcanalytics-13f07",
  storageBucket: "nfcanalytics-13f07.appspot.com",
  messagingSenderId: "924352318624",
  appId: "1:924352318624:web:f3ab46eb813a05c88c0dc5",
  measurementId: "G-GLEE92JS0N"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore and Auth
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth, serverTimestamp };
