import React, { useState, useRef } from 'react';
import { db, serverTimestamp } from '../firebase';
import { Container, Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { collection, doc, setDoc, query, where, getDocs } from 'firebase/firestore';
import { LoadScript, StandaloneSearchBox } from '@react-google-maps/api';
import config from '../config/config'; // Ensure this path points to your config file

function LinkForm() {
  const [ownerName, setOwnerName] = useState('');
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');
  const [link, setLink] = useState('');
  const [businessName, setBusinessName] = useState(''); // State to store the selected business name
  const [showAlert, setShowAlert] = useState(false);
  const [newLink, setNewLink] = useState('');
  const [error, setError] = useState('');
  const searchBoxRef = useRef(null);

  const handlePlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      const placeId = place.place_id;
      const name = place.name;

      // Store the business name
      setBusinessName(name);

      // Construct the Google review link
      const googleReviewLink = `https://search.google.com/local/writereview?placeid=${placeId}`;
      setLink(googleReviewLink);
      console.log(`Selected business: ${name}`);
      console.log(`Review link: ${googleReviewLink}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear any previous errors

    try {
      // Query Firestore to count how many links have been created with this email
      const linksCollectionRef = collection(db, 'links');
      const emailQuery = query(linksCollectionRef, where('email', '==', email));
      const querySnapshot = await getDocs(emailQuery);

      if (querySnapshot.size >= 5) {
        // If the user has already created 5 links, show an error and prompt to subscribe
        setError('You have reached the maximum number of links (5). Please subscribe to create more links.');
        return;
      }

      // Proceed to create a new link if the limit has not been reached
      const uniqueId = doc(linksCollectionRef).id;  // Generate a unique document ID
      const generatedLink = `${window.location.origin}/track/${uniqueId}`;  // Construct the tracking link

      // Include the business name in the document
      await setDoc(doc(linksCollectionRef, uniqueId), {
        ownerName,
        description,
        email,
        link,
        newLink: generatedLink,
        uniqueId,
        createdAt: serverTimestamp(),
        businessName, // Store business name
      });

      setNewLink(generatedLink);
      setShowAlert(true);
    } catch (error) {
      console.error('Error creating link:', error);
      setError('An error occurred while creating the link.');
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={6}>
          <h1 className="text-center mt-5">Create a New Link</h1>
          {showAlert && (
            <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
              Link created successfully! <a href={newLink} target="_blank" rel="noopener noreferrer">{newLink}</a>
            </Alert>
          )}
          {error && (
            <Alert variant="danger" onClose={() => setError('')} dismissible>
              {error}
            </Alert>
          )}
          <Form onSubmit={handleSubmit} className="mt-4">
            <Form.Group controlId="formOwnerName" className="mb-3">
              <Form.Label>Owner Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter owner name"
                value={ownerName}
                onChange={(e) => setOwnerName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formDescription" className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formBusinessSearch" className="mb-3">
              <Form.Label>Search for Business</Form.Label>
              <LoadScript googleMapsApiKey={config.GOOGLE_PLACES_API_KEY} libraries={['places']}>
                <StandaloneSearchBox
                  onLoad={(ref) => (searchBoxRef.current = ref)}
                  onPlacesChanged={handlePlacesChanged}
                >
                  <Form.Control
                    type="text"
                    placeholder="Search for a business"
                  />
                </StandaloneSearchBox>
              </LoadScript>
            </Form.Group>

            <Form.Group controlId="formLink" className="mb-3">
              <Form.Label>Link</Form.Label>
              <Form.Control
                type="url"
                placeholder="Enter link"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100">
              Create Link
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default LinkForm;
