// src/components/Cancel.js
import React from 'react';

function Cancel() {
  return (
    <div className="text-center mt-5">
      <h2>Payment Canceled</h2>
      <p>You have canceled the payment. Please try again.</p>
    </div>
  );
}

export default Cancel;
