import React, { useState } from 'react';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Container, Form, Button, Row, Col, Alert } from 'react-bootstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';

// Register necessary components for chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

function SearchAnalytics() {
  const [uniqueId, setUniqueId] = useState('');
  const [analytics, setAnalytics] = useState(null);
  const [error, setError] = useState('');

  const handleSearch = async (e) => {
    e.preventDefault();
    setError('');
    setAnalytics(null);

    try {
      const docRef = doc(db, 'links', uniqueId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setAnalytics(docSnap.data());
      } else {
        setError('No data found for this ID');
      }
    } catch (error) {
      console.error('Error fetching analytics:', error);
      setError('An error occurred while fetching the data');
    }
  };

  const getScanData = () => {
    if (!analytics || !analytics.interactions) return { labels: [], data: [] };

    const scanDates = analytics.interactions.map(interaction => new Date(interaction.timestamp).toLocaleDateString());
    const scanCounts = {};

    scanDates.forEach(date => {
      scanCounts[date] = (scanCounts[date] || 0) + 1;
    });

    return {
      labels: Object.keys(scanCounts),
      data: Object.values(scanCounts)
    };
  };

  const getDeviceData = () => {
    if (!analytics || !analytics.interactions) return { labels: [], data: [] };

    const deviceCounts = {};

    analytics.interactions.forEach(interaction => {
      const device = interaction.device.includes('Mobile') ? 'Mobile' : 'Desktop';
      deviceCounts[device] = (deviceCounts[device] || 0) + 1;
    });

    return {
      labels: Object.keys(deviceCounts),
      data: Object.values(deviceCounts)
    };
  };

  const getBrowserData = () => {
    if (!analytics || !analytics.interactions) return { labels: [], data: [] };

    const browserCounts = {};

    analytics.interactions.forEach(interaction => {
      const browser = interaction.device.includes('Chrome') ? 'Chrome' :
                      interaction.device.includes('Firefox') ? 'Firefox' :
                      interaction.device.includes('Safari') ? 'Safari' : 'Other';
      browserCounts[browser] = (browserCounts[browser] || 0) + 1;
    });

    return {
      labels: Object.keys(browserCounts),
      data: Object.values(browserCounts)
    };
  };

  const getRepeatUserCount = () => {
    if (!analytics || !analytics.interactions) return 0;

    const userSet = new Set();
    analytics.interactions.forEach(interaction => {
      userSet.add(interaction.device); // Simplification: using user agent as a proxy for uniqueness
    });

    return analytics.interactions.length - userSet.size;
  };

  const scanData = getScanData();
  const deviceData = getDeviceData();
  const browserData = getBrowserData();
  const repeatUserCount = getRepeatUserCount();

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={6}>
          <h1 className="text-center mt-5">Search Analytics</h1>
          <Form onSubmit={handleSearch} className="mt-4">
            <Form.Group controlId="formUniqueId" className="mb-3">
              <Form.Label>Enter Unique ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your unique ID"
                value={uniqueId}
                onChange={(e) => setUniqueId(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100">
              Search
            </Button>
          </Form>

          {error && (
            <Alert variant="danger" className="mt-4">
              {error}
            </Alert>
          )}

          {analytics && (
            <div className="mt-4">
              <h3>Analytics for ID: {uniqueId}</h3>
              <div className="mb-4" style={{ height: '300px' }}>
                <h4>Total Scans Over Time</h4>
                <Bar
                  data={{
                    labels: scanData.labels,
                    datasets: [{
                      label: 'Number of Scans',
                      data: scanData.data,
                      backgroundColor: 'rgba(75,192,192,0.4)',
                      borderColor: 'rgba(75,192,192,1)',
                      borderWidth: 1
                    }]
                  }}
                  options={{ maintainAspectRatio: false }}
                />
              </div>
              <div className="mb-4">
                <h4>Device Usage</h4>
                <Pie
                  data={{
                    labels: deviceData.labels,
                    datasets: [{
                      data: deviceData.data,
                      backgroundColor: ['#FF6384', '#36A2EB'],
                      hoverBackgroundColor: ['#FF6384', '#36A2EB']
                    }]
                  }}
                />
              </div>
              <div className="mb-4">
                <h4>Browser Usage</h4>
                <Pie
                  data={{
                    labels: browserData.labels,
                    datasets: [{
                      data: browserData.data,
                      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#AA65CC'],
                      hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#AA65CC']
                    }]
                  }}
                />
              </div>
              <div className="mb-4">
                <h4>Repeat Users</h4>
                <p>{repeatUserCount} users scanned more than once.</p>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default SearchAnalytics;
