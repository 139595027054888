import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { doc, updateDoc, arrayUnion, serverTimestamp, getDoc } from 'firebase/firestore';

function TrackLink() {
  const { id } = useParams(); // Get the unique ID from the URL

  useEffect(() => {
    const redirectToLink = async () => {
      try {
        // Fetch the document to get the original link
        const docRef = doc(db, 'links', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const { link } = docSnap.data();
          console.log("Document data:", docSnap.data());

          const userAgent = navigator.userAgent;
          const sessionStart = new Date(); // Capture session start time

          // Optional: Use IP geolocation service for approximate location
          const response = await fetch('https://ipapi.co/json/');
          const locationData = await response.json();

          console.log("Location data:", locationData);

          // Update the document by adding the interaction to an array
          await updateDoc(docRef, {
            interactions: arrayUnion({
              timestamp: sessionStart.toISOString(),
              device: userAgent,
              location: {
                city: locationData.city || 'Unknown',
                country: locationData.country_name || 'Unknown'
              },
              networkType: navigator.connection ? navigator.connection.effectiveType : 'Unknown',
              referrer: document.referrer || 'Direct',
            }),
            lastAccessed: serverTimestamp()
          });

          console.log("Interaction logged successfully");

          // Redirect after logging the interaction
          window.location.href = link;

        } else {
          console.error("No such document!");
          alert('Link not found');
        }
      } catch (error) {
        console.error("Error tracking link:", error);
      }
    };

    redirectToLink();
  }, [id]);

  return (
    <div>
      <p>Tracking your link usage...</p>
    </div>
  );
}

export default TrackLink;
